
.menu-siderbar {
    width: 240px;
    min-height: calc(100vh - 56px);
    background: #edf0f1;
    border-right: 1px solid #dfe4e6;
}
.menu-siderbar .nav-item {
    position: relative;
}
.menu-siderbar .nav-item .nav-link-parent:after {
    position: absolute;
    content: "\f107";
    font-family: FontAwesome;
    font-size: 12px;
    line-height: 10px;
    right: 14px;
    top: 17px;
}
.menu-siderbar .nav-item.open .nav-link-parent:after {
    content: "\F282";
}
.menu-siderbar .nav-item .nav-link {
    color: #626b7a;
}
.menu-siderbar .nav-item .nav-link:hover {
    color: #1e2125;
    background: #fafafa;
}
.menu-siderbar .nav-item .nav-link.active {
    color: #1e2125;
    background: #fafafa;
}
.menu-siderbar .nav-item .nav-link .menu-icon {
    margin-right: 6px;
}
.menu-siderbar .nav-item .menu-siderbar-child {
    display: none;
}
.menu-siderbar .nav-item .menu-siderbar-child .nav-link {
    padding-left: calc(1rem + 15px);
}
.menu-siderbar .nav-item.open .menu-siderbar-child{
    display: block;
}
