/*--------------------------------------------------------------
# General
--------------------------------------------------------------*/
body {
  /* font-family: "Open Sans", sans-serif; */
  /* font-family: 'Arial, Helvetica, sans-serif'; */
  color: #444444;
  height: 100vh;
  width: 100vw;
}

* {
  font-family: "verdana"
    /*'Arial, Helvetica, sans-serif'*/
  ;
}

a {
  text-decoration: none;
  color: #0880e8;
}

a:hover {
  color: #2b99f8;
  text-decoration: none;
}

/*--------------------------------------------------------------
# Back to top button
--------------------------------------------------------------*/
.back-to-top {
  position: fixed;
  visibility: hidden;
  opacity: 0;
  right: 15px;
  bottom: 15px;
  z-index: 996;
  background: #0880e8;
  width: 40px;
  height: 40px;
  border-radius: 50px;
  transition: all 0.4s;
}

.back-to-top i {
  font-size: 28px;
  color: #fff;
  line-height: 0;
}

.back-to-top:hover {
  background: #2194f7;
  color: #fff;
}

.back-to-top.active {
  visibility: visible;
  opacity: 1;
}

/*--------------------------------------------------------------
# Preloader
--------------------------------------------------------------*/
#preloader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  overflow: hidden;
  background: #fff;
}

#preloader:before {
  content: "";
  position: fixed;
  top: calc(50% - 30px);
  left: calc(50% - 30px);
  border: 6px solid #0880e8;
  border-top-color: #bfe0fd;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: animate-preloader 1s linear infinite;
}

@keyframes animate-preloader {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/*--------------------------------------------------------------
# Disable aos animation delay on mobile devices
--------------------------------------------------------------*/
@media screen and (max-width: 768px) {
  [data-aos-delay] {
    transition-delay: 0 !important;
  }
}

/*--------------------------------------------------------------
# Top Bar
--------------------------------------------------------------*/
#topbar {
  height: 60px;
  padding: 0;
  font-size: 14px;
  transition: all 0.5s;
  overflow: hidden;
  color: rgba(255, 255, 255, 0.8);
  z-index: 996;
}

#topbar.topbar-scrolled {
  top: -60px;
}

#topbar .contact-info a {
  line-height: 0;
  color: rgba(255, 255, 255, 0.8);
  transition: 0.3s;
}

/* #topbar .contact-info a:hover {
  text-decoration: none;
} */

#topbar .contact-info i {
  color: #f6b024;
  line-height: 0;
  margin-right: 5px;
}

#topbar .contact-info .phone-icon {
  margin-left: 15px;
}

#topbar .cta {
  background: transparent;
}

#topbar .cta a {
  color: #fff;
  background: #f6b024;
  padding: 6px 24px 8px 24px;
  display: inline-block;
  transition: 0.3s;
  border-radius: 50px;
}

#topbar .cta a:hover {
  background: #f1a40a;
}

/*--------------------------------------------------------------
# Header
--------------------------------------------------------------*/
#header {
  background: rgba(0, 0, 0, 0.1);
  transition: all 0.5s;
  z-index: 997;
  height: 70px;
  top: 60px;
  position: sticky;
}

#header.header-scrolled {
  background: rgba(5, 87, 158, 0.9);
  top: 0;
}

#header {
  background: rgba(5, 87, 158, 0.9);
  top: 0;
}

#header .logo {
  font-size: 24px;
  margin: 0;
  padding: 0;
  line-height: 1;
  font-weight: 400;
  letter-spacing: 2px;
}

#header .logo a {
  color: #fff;
  padding: 10px;
}

#header .logo img {
  max-height: 40px;
}

.header-inner-pages {
  background: rgba(5, 87, 158, 0.9) !important;
}

.topbar-inner-pages {
  background: rgba(6, 98, 178, 0.9) !important;
}

/*--------------------------------------------------------------
# Navigation Menu
--------------------------------------------------------------*/
/**
* Desktop Navigation 
*/
.navbar {
  padding: 0;
}

.navbar ul {
  margin: 0;
  padding: 0;
  display: flex;
  list-style: none;
  align-items: center;
}

.navbar li {
  position: relative;
}

.navbar>ul>li {
  position: relative;
  white-space: nowrap;
  padding: 10px 0 10px 24px;
}

.navbar a,
.navbar a:focus {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 3px;
  font-size: 14px;
  color: rgba(255, 255, 255, 0.7);
  white-space: nowrap;
  transition: 0.3s;
  position: relative;
}

.navbar a i,
.navbar a:focus i {
  font-size: 12px;
  line-height: 0;
  margin-left: 5px;
}

.navbar>ul>li>a:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: -5px;
  left: 0;
  background-color: #f6b024;
  visibility: hidden;
  width: 0px;
  transition: all 0.3s ease-in-out 0s;
}

.navbar a:hover:before,
.navbar li:hover>a:before,
.navbar .active:before {
  visibility: visible;
  width: 100%;
}

.navbar a:hover,
.navbar .active,
.navbar .active:focus,
.navbar li:hover>a {
  color: #fff;
}

.navbar .dropdown ul {
  display: block;
  position: absolute;
  left: 14px;
  top: calc(100% + 30px);
  margin: 0;
  padding: 10px 0;
  z-index: 99;
  opacity: 0;
  visibility: hidden;
  background: #fff;
  box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
  transition: 0.3s;
  border-radius: 8px;
}

.navbar .dropdown ul li {
  min-width: 200px;
}

.navbar .dropdown ul a {
  padding: 10px 20px;
  font-size: 14px;
  font-weight: 500;
  text-transform: none;
  color: #032e54;
}

.navbar .dropdown ul a i {
  font-size: 12px;
}

.navbar .dropdown ul a:hover,
.navbar .dropdown ul .active:hover,
.navbar .dropdown ul li:hover>a {
  color: #0880e8;
}

.navbar .dropdown:hover>ul {
  opacity: 1;
  top: 100%;
  visibility: visible;
}

.navbar .dropdown .dropdown ul {
  top: 0;
  left: calc(100% - 30px);
  visibility: hidden;
}

.navbar .dropdown .dropdown:hover>ul {
  opacity: 1;
  top: 0;
  left: 100%;
  visibility: visible;
}

@media (max-width: 1366px) {
  .navbar .dropdown .dropdown ul {
    left: -90%;
  }

  .navbar .dropdown .dropdown:hover>ul {
    left: -100%;
  }
}

/**
* Mobile Navigation 
*/
.mobile-nav-toggle {
  color: #fff;
  font-size: 28px;
  cursor: pointer;
  display: none;
  line-height: 0;
  transition: 0.5s;
}

.mobile-nav-toggle.bi-x {
  color: #f6b024;
}

@media (max-width: 991px) {
  .mobile-nav-toggle {
    display: block;
  }

  .navbar ul {
    display: none;
  }
}

.navbar-mobile {
  position: fixed;
  overflow: hidden;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: rgba(5, 74, 133, 0.9);
  transition: 0.3s;
  z-index: 999;
}

.navbar-mobile .mobile-nav-toggle {
  position: absolute;
  top: 15px;
  right: 15px;
}

.navbar-mobile ul {
  display: block;
  position: absolute;
  top: 55px;
  right: 15px;
  bottom: 15px;
  left: 15px;
  padding: 10px 0;
  border-radius: 10px;
  background-color: #fff;
  overflow-y: auto;
  transition: 0.3s;
}

.navbar-mobile>ul>li {
  padding: 0;
}

.navbar-mobile a,
.navbar-mobile a:focus {
  padding: 10px 20px;
  font-size: 15px;
  color: #0665b7;
}

.navbar-mobile a:hover:before,
.navbar-mobile li:hover>a:before,
.navbar-mobile .active:before {
  visibility: hidden;
}

.navbar-mobile a:hover,
.navbar-mobile .active,
.navbar-mobile li:hover>a {
  color: #f6b024;
}

.navbar-mobile .getstarted,
.navbar-mobile .getstarted:focus {
  margin: 15px;
}

.navbar-mobile .dropdown ul {
  position: static;
  display: none;
  margin: 10px 20px;
  padding: 10px 0;
  z-index: 99;
  opacity: 1;
  visibility: visible;
  background: #fff;
  box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
}

.navbar-mobile .dropdown ul li {
  min-width: 200px;
}

.navbar-mobile .dropdown ul a {
  padding: 10px 20px;
}

.navbar-mobile .dropdown ul a i {
  font-size: 12px;
}

.navbar-mobile .dropdown ul a:hover,
.navbar-mobile .dropdown ul .active:hover,
.navbar-mobile .dropdown ul li:hover>a {
  color: #f6b024;
}

.navbar-mobile .dropdown>.dropdown-active {
  display: block;
}


/*--------------------------------------------------------------
# styles
--------------------------------------------------------------*/
#root{
height: 100%;
}




.pageHeading {
  color: #333;
  /* margin-bottom: 10px; */
  /* text-transform: uppercase; */
  font-family: "verdana"
    /*'Arial, Helvetica, sans-serif'*/
  ;
  font-size: 20px;
}
.widthfitcontent{
  width: fit-content;
}
.issuecontainer.container-fluid{
  height:100%;
}

.wrkgrkcontainer.container-fluid{
  height:100%;
}

.gridcontainer{
  height: 75%;
}

.ddlgrid .ag-row{
  pointer-events: none;
}

.ddlgrid button
, .ddlgrid .ag-cell-inline-editing
, .ddlgrid .ag-wrapper
{
  pointer-events: all;
}

.mygrid .ag-header-row-column {
  background-color: #cddde3 !important;
}
.mygrid .ag-body .ag-body-viewport 
{
  overflow-y: hidden !important;
}

/* .mygrid .ag-header-container .ag-header-row-column-filter, .mygrid .ag-header-container .ag-header-row-column {
  width: 100% !important; ;
} */
.ddlgrid .ag-header-row-column {
  background-color: #05bae2 !important;
}
.gridcol{
  padding: 0px !important;
}
.gridheight{
  height: 85%;
}

.wrkgrpgridcontainer{
  height: 70%;
}

.boxborder {
  border-style: solid;
  border-color: rgb(201 201 201);
  border-width: 0.5px;
  padding: 10px;
  border-radius: 4px;
  justify-content: space-around;
}

.col, #adfilter .row+.row .col-3, #issueform .col-6, .wkfilter .col-3  {
  padding: 10px
}
 
.logobg {
  padding: 15px;
  background-color: white
}

.maincontent.container-fluid {
  padding-top: 20px;
  padding-left: 30px;
  padding-right: 30px;
  height: 90%;
}

/* .ddladminarea{height: 100%;}

.maincontent{
height: 100%;
position:fixed
} */

.tocenter {
  align-items: center;
}

.textcenter {
  text-align: center;
}

/* #sidescroll {
  width: 500px;
  height: 500px;
  overflow: auto;
  }

  .sidescroll .col{
    padding-bottom: 0px;
  } */

#adfilter .col , #adfilter .col-3 {
  padding-top: 0px;
  /* padding-left: 0px; */
}

.issuesearchform div col,
.issuesearchform div col-2,
.issuesearchform div col-8 {
  width: auto;
}

/* .whitespaceNoWrap {
  white-space: nowrap;
} */


#adfilter .row+.row .col, #adfilter .row+.row .col-3 {
  padding-bottom: 0px;
}

#searchdate input {
  height: 8px
}

/* #formControl {
  max-width: 148.62px;
} */

.ctrlsize
{
  height: 40px !important;
}

div.dataTables_info {
  padding-top: 8px;
  white-space: nowrap;
  float: left;
  width: 100%;
}


/* .col{
  padding-left: 15px;
  padding-right: 15px;
} */

.btnfitright {
  float: right;
  width: fit-content;
}

.maincontent, #fontsizesmall,
#demo-multiple-checkbox-label[data-shrink~="false"],
#outlined-size-small-label[data-shrink~="false"],
#enforcementId-label[data-shrink~="false"],
#duedateId-label[data-shrink~="false"],
#openToCust span,
#enforeDetailId-label[data-shrink~="false"],
#enforeDetailId,
#enforcementId,
#duedateId,
#actionDetailId-label[data-shrink~="false"],
#actionDetailId,
ul[role~="listbox"] li[role~="option"],
ul[role~="listbox"] li[role~="option"] div span,
#actiondetailslist span,
.font12,
#outlined-size-small,
#fontsizesmall-label[data-shrink~="false"] ,
#opendate ,
#opendate-label,
#closedate ,
#closedate-label ,
#jurisdiction,
#WStatus,
#county_Districtsid,
#county_Districtsid-label[data-shrink~="false"],
#urlid,
#urlid-label[data-shrink~="false"],
#alturlid,
#alturlid-label[data-shrink~="false"],
#state_Provinceid,
#state_Provinceid-label[data-shrink~="false"],
#city_Municipalitiesid,
#city_Municipalitiesid-label[data-shrink~="false"]
{
  font-size: 12px !important;
} 

#jurisdiction,
#WStatus
{
text-align: left;
}

#actiondetailslist p{
  font-size: 8px;
}

#enforcementId,
#enforeDetailId,
#duedateId,
#urlid,
#alturlid,
#state_Provinceid,
#county_Districtsid,
#city_Municipalitiesid,
#actionDetailId {
  height: 8px;
}

div.ag-theme-alpine div.ag-row,
.ag-header-container,
.ag-paging-panel {
  font-size: 10px;
}

#actiondetailslist li div svg {
  font-size: 18px;
}

html,
body {
  max-width: 100%;
overflow-x: hidden;
}

.padding10{
  padding: 10px;
}

.mybtn {
  margin: 5px;
  min-height: 30px;
  min-width: 64px;
}

.overflowauto{
  overflow: auto;
}


.tabname {
  font-size: 12px !important;
    text-transform: none !important;
}

.nonebgcolor{
/* background-color: #dcdcdc; */
}

.ddlgrid .ag-header-row-column-filter{
  background-color: white;
}
.adminsidemenu.list-group-item.active{
  background-color: #05bae2;
  border-color: #05bae2;
}

/* 
.css-1pysi21-MuiFormLabel-root-MuiInputLabel-root {
  font-size: 0.8rem !important
} */

/* .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root {
  font-size: 0.6rem !important
}

.css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
  font-size: 0.8rem !important
}

.css-ahj2mt-MuiTypography-root {
  font-size: 0.8rem !important
} */

label [data-shrink~="true"],#demo-multiple-checkbox-label [data-shrink~="true"],
.ag-row-odd, .labelbgwhite {
  background-color: #f0f0f0 !important
}

.ag-header-viewport, .ag-pinned-right-header  {
  background-color: #d3d3d3 !important;
}

.css-1jy569b-MuiFormLabel-root-MuiInputLabel-root, .labelbgwhite {
  background-color: white !important;
}

.box-shadow{
	-webkit-box-shadow: 0 1px 1px rgba(72,78,85,.6);
	box-shadow: 0 1px 1px rgba(72,78,85,.6);
	-webkit-transition: all .2s ease-out;
	-moz-transition: all .2s ease-out;
	-ms-transition: all .2s ease-out;
	-o-transition: all .2s ease-out;
	transition: all .2s ease-out;
}
 
.box-shadow:hover{
	-webkit-box-shadow: 0 20px 40px rgba(72,78,85,.6);
	box-shadow: 0 20px 40px rgba(72,78,85,.6);
	-webkit-transform: translateY(-15px);
	-moz-transform: translateY(-15px);
	-ms-transform: translateY(-15px);
	-o-transform: translateY(-15px);
	transform: translateY(-15px);
}

.headerborderstyle{
  border-bottom: #cee492;
    border-bottom-width: 2px;
    border-bottom-style: solid;
    width: fit-content;
    border-left: #6c900c;
    border-left-width: 5px;
    border-left-style: solid;
    padding: 4px;
}

.tox-statusbar__branding, .tox-statusbar {
  display: none !important;
}

.leftpaddingzero{
  padding-left: 0px;
}

.pointerCurser{
cursor: pointer;
}

.issuegrd >*{
  padding: 0px;
}

.ag-header-cell, .ag-header-group-cell{
  padding-right: 0px;
}

.ag-cell, .ag-full-width-row .ag-cell-wrapper.ag-row-group{
  padding-right: 0px;
}



/* fileupload  */

.file-upload {
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
}
 
.file-upload h2 {
  text-align: center;
  margin-bottom: 20px;
}
 
.file-upload input[type="file"] {
  display: block;
  margin-bottom: 10px;
  height: 50px;
}
 
.file-upload button {
  display: block;
  width: 100%;
  padding: 10px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}
 
.file-upload button:hover {
  background-color: #0056b3;
}
 
.file-upload .error {
  color: red;
  margin-top: 10px;
}
 
.file-upload .success {
  color: green;
  margin-top: 10px;
}

.file-upload .warning {
  color: rgb(255, 125, 11);
  margin-top: 10px;
}

.dataUploadContainer{
  height: 80vh;
  display: flex;
  /* align-items: center; */
}